@import '../components/global-styles/media-mixins';
@import '../components/global-styles/font-families';

.official {
  position: relative;
  padding-bottom: 95px;

  &__skate {
    position: absolute;
    top: 40vw;
    right: -15px;
    width: 30%;
  }

  &__selections {
    &-selection {
      h2 {
        margin-top: 80px;
        margin-bottom: 30px;
        font: 16px $roboto;
        font-weight: bold;
      }
    }

    &-movies {
      &-movie {
        font: 16px $roboto;
        margin-bottom: 10px;

        &-name {
          color: #343F99;
          font: 18px $roboto;
          margin-bottom: 10px;
        }

        &-director {
          font: 16px $roboto;
          margin-bottom: 10px;
          font-style: italic;
        }
      }

      &-row {
        margin-bottom: 50px;
      }
    }
  }
}

@include tablet() {
  .official {
    &__selections {
      margin-left: 100px;
    }
  }
}

@include desktop-lowres() {
  .official {
    padding-bottom: 130px;

    &__skate {
      top: 250px;
      right: -15px;
      width: 20%;
    }

    &__selections {
      margin-top: -100px;

      &-selection {
        h2 {
          margin-top: 80px;
          margin-bottom: 40px;
          font-size: 24px;
        }
      }

      &-movies {
        &-movie {
          font-size: 18px;

          &-name {
            font-size: 20px;
          }

          &-director {
            font-size: 18px;
          }
        }
      }
    }
  }
}

@include desktop() {
  .official {
    &__skate {
      right: -50px;
      width: 30%;
    }

    &__selections {
      margin-left: 150px;
    }
  }
}