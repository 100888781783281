@import '../components/global-styles/media-mixins';
@import '../components/global-styles/font-families';

.menu {
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  padding: 0;
  width: 40px;
  z-index: 11;
}

.line {
  fill: none;
  stroke: rgb(18, 25, 42);
  ;
  stroke-width: 6;
  transition: stroke-dasharray 600ms cubic-bezier(0.4, 0, 0.2, 1),
    stroke-dashoffset 600ms cubic-bezier(0.4, 0, 0.2, 1);
}

.line1 {
  stroke-dasharray: 60 207;
  stroke-width: 6;
}

.line2 {
  stroke-dasharray: 60 60;
  stroke-width: 6;
}

.line3 {
  stroke-dasharray: 60 207;
  stroke-width: 6;
}

.opened .line1 {
  stroke-dasharray: 90 207;
  stroke-dashoffset: -134;
  stroke-width: 6;
}

.opened .line2 {
  stroke-dasharray: 1 60;
  stroke-dashoffset: -30;
  stroke-width: 6;
}

.opened .line3 {
  stroke-dasharray: 90 207;
  stroke-dashoffset: -134;
  stroke-width: 6;
}



.nav {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
  padding: 22px 26px;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
  z-index: 10;

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__lower {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;

    &-links {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
    }
  }

  &__submit {
    display: none;
  }

  &__last-items {
    display: none;
  }

  &__socials {
    display: none;
  }

  &__lang {
    display: none;
  }

  &__logo {
    position: relative;
    width: 34px;
    height: 23px;
    z-index: 12;
  }

  &__links {
    display: none;
  }

  &__overlay {
    position: absolute;
    top: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 100dvh;
    padding: 75px 0 80px 0;
    transition: left .7s cubic-bezier(0.075, 0.82, 0.165, 1);
    color: black;
    background-size: cover;
    background-position: right;
    background-repeat: no-repeat;

    &-submit-button {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 15px 25px;
      background-color: #343F99;
      font: 16px $roboto;
      font-weight: 900;
      text-transform: uppercase;
      color: #F4D251;
      transition: all .3s;

      &:hover {
        background-color: #F4D251;
        color: #343F99;
      }
    }

    &-links {
      display: flex;
      flex-direction: column;

      div {
        margin-top: 20px;
      }
    }

    &-link {
      color: black;
      font: 30px $roboto;
      font-weight: 900;
      line-height: 1.7;
      text-align: center;

      &--active {
        color: #343F99;
      }
    }

    &-socials {
      a {
        display: inline-block;
        margin-right: 30px;

        &:last-of-type {
          margin-right: 0;
        }

        svg {
          width: 30px;

          path {
            fill: black !important;
          }
        }
      }
    }
  }
}

@include tablet() {
  .nav {
    padding: 22px 50px;
  }
}

@include desktop-lowres() {
  .nav {
    padding: 25px 0 25px 0;

    &__header {
      max-width: 900px;
      margin: 0 auto;
    }

    &__lower {
      display: flex;
      align-items: center;
      padding-top: 18px;

      &-links {
        width: fit-content;
      }
    }

    &__upper {
      display: flex;
      justify-content: flex-end;
      width: 100%;
      padding-bottom: 10px;
      border-bottom: 1px solid #e4e7ea;
    }

    &__submit {
      display: inline-block;
      margin-left: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 10px 20px;
      background-color: #343F99;
      font: 14px $roboto;
      font-weight: 900;
      text-transform: uppercase;
      color: #F4D251;
      transition: background-color .3s, color .3s;

      &:hover {
        background-color: #F4D251;
        color: #343F99;
      }
    }

    &__overlay {
      display: none;
    }

    &__socials {
      display: flex;
      align-content: center;
      margin-right: 40px;

      a {
        display: inline-block;
        margin-right: 20px;

        &:last-of-type {
          margin-right: 0;
        }

        svg {
          width: 20px;
          height: 20px;
        }

        path {
          fill: #030504 !important;
          transition: fill .3s;
        }

        &:hover {
          path {
            fill: #343F99 !important;
          }
        }
      }
    }

    &__lang {
      display: block;
    }

    &__last-items {
      display: flex;
    }

    &__links {
      display: flex;
      justify-self: flex-start;
      margin-left: 50px;

      &-link {
        font: 16px $roboto;
        color: #030504;
        opacity: 1;
        transition: opacity .3s;

        &:hover {
          opacity: 0.6;
        }

        &--active {
          color: #343F99;
        }
      }

      div {
        margin-right: 30px;

        &:last-of-type {
          margin-right: 0;
        }
      }
    }
  }

  .menu {
    display: none;
  }
}

@include desktop() {
  .nav {
    &__header {
      max-width: 1200px;
    }

    &__submit {
      margin-left: 100px;
    }

    &__links {
      margin-left: 100px;

      div {
        margin-right: 50px;
      }
    }
  }
}