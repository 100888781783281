@import '../components/global-styles/media-mixins';
@import '../components/global-styles/font-families';

.awards {
  padding-bottom: 90px;
  &__text {
    margin-top: 10px;
    font: 16px $roboto;
  }
}

@include desktop-lowres() {
  .awards {
    padding-bottom: 130px;
    &__text {
      font-size: 18px;
      max-width: 950px;
      margin: 20px auto;
    }
  }
}