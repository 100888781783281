@import '../components/global-styles/media-mixins';
@import '../components/global-styles/font-families';

.jury {
  padding-bottom: 90px;

  &__text {
    font: 16px $roboto;
    line-height: 1.8;
    margin-bottom: 60px;

    p {
      font: 16px $roboto;
      line-height: 1.8;
    }

    &-bold {
      font: 16px $roboto;
      font-weight: 700;
    }
  }

  &__jurors {
    &-single {
      margin-bottom: 70px;

      &:last-of-type {
        margin-bottom: 0;
      }

      p {
        font: 16px $roboto;
        line-height: 1.8;
        margin-bottom: 15px;
      }

      span {
        margin-bottom: 25px;
      }

      span,
      img {
        width: 100%;
      }

      &-no-image {
        margin-bottom: 30px;
      }
    }
  }
}

@include desktop-lowres() {
  .jury {
    padding-bottom: 200px;

    &__text {
      max-width: 950px;
      margin: 0px auto 200px auto;
      font-size: 20px;

      &:last-of-type {
        margin: 0px auto 0px auto;
      }

      p {
        font-size: 20px;
      }

      &-bold {
        max-width: 950px;
        margin: 20px auto;
        font-size: 20px;
      }
    }

    &__jurors {
      &-single {
        display: flex;
        margin-bottom: 170px;

        p {
          font-size: 20px;
        }

        span,
        img {
          width: 400px;
          margin-right: 50px;
        }
      }
    }
  }
}

@include desktop() {
  .jury {
    &__jurors {
      &-single {

        span,
        img {
          width: 500px;
          margin-right: 60px;
        }
      }
    }
  }
}