@import '../components/global-styles/media-mixins';
@import '../components/global-styles/font-families';

.news {
  padding-top: 60px;
  padding-bottom: 30px;
  min-height: 100vh;
  &__title {
    margin-bottom: 30px;
    font: 20px $roboto;
    font-weight: 700;
  }
  &__archive {
    &-wrapper {
      margin-bottom: 60px;
      img {
        width: 100%;
        margin-bottom: 30px;
      }
      h2 {
        margin-bottom: 20px;
        font: 25px $roboto;
        font-weight: 700;
        color: #272727;
      }
      p {
        font: 14px $roboto;
        color: #272727;
      }
      &:hover {
        h2 {
          text-decoration: underline;
        }
      }
    }
  }
}

@include tablet() {
  .news {
    display: flex;
    justify-content: center;
    &__archive {
      max-width: 400px;
    }
  }
}

@include desktop-lowres() {
  .news {
    display: block;
    padding-top: 132px;
    padding-bottom: 200px;
    &__title {
      max-width: 955px;
      margin: 0 auto 50px;
    }
    &__archive {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      column-gap: 22px;
      max-width: 955px;
      margin: 0 auto;
      a {
        width: 285px;
      }
      &-wrapper {
        max-width: 285px;
        img {
          height: 343px;
          width: 285px;
        }
      }
    }
  }
}

@include desktop() {
  .news {
    &__archive {
      column-gap: 50px;
    }
  }
}