@import '../components/global-styles/media-mixins';
@import '../components/global-styles/font-families';

.off-logo {
  width: 100px !important;
}

.rtv-logo {
  width: 180px !important;
}

.asfm-logo {
  width: 190px !important;
}

.plesir-logo {
  width: 170px !important;
}

.sponsors {
  &__logos {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 100px;

    &-row {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;
      flex-wrap: wrap;
    }

    &-logo {
      width: 170px;
    }

    &-link {
      display: inline-block;
      margin-top: 70px;
    }
  }
}

.oblakoder {
  width: 120px;
}

@include desktop-lowres() {
  .sponsors {
    display: flex;
    align-items: center;
    min-height: calc(100vh - 113px);
    padding-bottom: 130px;

    &__logos {
      justify-content: center;
      width: 720px;
      padding: 0;

      &-row {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 20px;
        justify-content: space-between;
      }

      &-logo {
        width: 210px;

        &:first-of-type {
          width: 240px;
        }
      }

      &-link {
        display: flex;
        justify-content: center;
        margin-top: 70px;
      }
    }
  }

  .oblakoder {
    width: 120px !important;
  }
}