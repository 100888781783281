@import '../components/global-styles/media-mixins';
@import '../components/global-styles/font-families';

.hero {
  padding-top: 60px;
  padding-bottom: 35px;

  &__title {
    margin-bottom: 30px;
    font: 20px $roboto;
    font-weight: 700;
  }

  &__image {
    width: 100%;
    height: auto;
  }

  &__container {
    span {
      width: 100%;
    }
  }
}

@include desktop-lowres() {
  .hero {
    padding-top: 70px;
    padding-bottom: 90px;

    &__container {
      display: flex;
      flex-direction: column-reverse;
    }

    &__title {
      max-width: 1020px;
      margin: 50px auto 0;
      font: 40px $roboto;
      font-weight: 700;
      line-height: 1.70;
      color: #343F99;
      text-align: center;
    }
  }
}

@include desktop() {
  .hero {
    &__title {
      margin-top: 70px;
    }
  }
}