@import '../components/global-styles/font-families';
@import '../components/global-styles/media-mixins';

.lang {
  display: flex;
  justify-content: space-between;
  width: 100px;

  &__button {
    &--active {
      color: #343F99;
    }
  }

  div {
    font: 28px $roboto;
    cursor: pointer;
  }
}

@include desktop-lowres() {
  .lang {
    width: 50px;

    &__button {
      text-decoration: none;
      color: #030504;

      &--active {
        color: #343F99 !important;
      }
    }

    div {
      font-size: 17px;
    }
  }
}