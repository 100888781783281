@import '../components/global-styles/media-mixins';
@import '../components/global-styles/font-families';

.zombie {
  padding-bottom: 120px;
  &__text {
    font: 18px $roboto;
    line-height: 1.7;
    margin-bottom: 10px;
  }
}

@include desktop-lowres() {
  .zombie {
    margin: 0 auto;
    &__text {
      max-width: 950px;
      margin: 0 auto;
    }
    p {
      font-size: 18px;
    }
  }
}