@import '../components/global-styles/media-mixins';
@import '../components/global-styles/font-families';

.volonter {
  padding-bottom: 90px;
  &-bold {
    font-weight: bold !important;
  }
  &__wrapper {
    p {
      font: 16px $roboto;
      line-height: 1.8;
      margin-bottom: 10px;
    }
  }
  &__date {
    display: flex;
  }
  &__list {
    padding-left: 30px;
    margin-bottom: 10px;
    li {
      margin-bottom: 5px;
      font: 16px $roboto;
      margin-bottom: 10px;
      list-style: outside;
      line-height: 1.8;
      list-style-type: decimal;
    }
  }
}

@include desktop-lowres() {
  .volonter {
    padding-bottom: 130px;
    &__wrapper {
      max-width: 950px;
      margin: 0px auto 0;
      p {
        font-size: 18px;
        margin-bottom: 20px;
        line-height: 1.9;
      }
    }
    li {
      font-size: 18px;
      line-height: 1.8;
      margin-bottom: 5px;
    }
    &__list {
      margin-bottom: 40px;
      padding-left: 50px;
    }
  }
}