@import '../components/global-styles/media-mixins';
@import '../components/global-styles/font-families';

.about {
  padding-bottom: 50px;

  &__text {
    p {
      font: 16px $roboto;
      line-height: 1.63;
      margin-bottom: 30px;
    }

    &-pink {
      width: 80% !important;
      color: #343F99;
      font-size: 18px;
      font-style: italic;
    }

    &-bigger {
      font-size: 18px;
      width: 80% !important;
      font-style: italic;
    }
  }
}

@include desktop-lowres() {
  .about {
    padding-bottom: 90px;

    &__text {
      p {
        margin: 0;
        font-size: 18px;
        line-height: 1.83;
        height: fit-content;

        &:first-of-type {
          grid-row: 1/2;
          grid-column: 1/3;
        }
      }

      &-pink {
        display: inline-block;
        width: 80%;
        color: #343F99;
        font-size: 20px;
        margin-left: 90px;
      }

      &-bigger {
        display: inline-block;
        width: 80%;
        font-size: 20px;
        margin-left: 90px;
      }
    }
  }
}

@include desktop() {
  .about {
    padding-bottom: 130px;

    &__text {
      max-width: 950px;
      margin: 0 auto;
      column-gap: 150px;

      &-pink,
      &-bigger {
        margin-left: 100px;
      }
    }
  }
}