@import '../components/global-styles/media-mixins';
@import '../components/global-styles/font-families';
@import '../components//global-styles/media-mixins';

.footer {
  background-color: #343F99;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 80px 0 50px;
  text-align: center;
  color: white;

  &--home {
    background-color: black;
  }

  &__info {
    &-contact {
      font: 14px $roboto;
      text-decoration: underline;
      margin-bottom: 16px;
    }

    &-email {
      font: 14px $roboto;
      margin-bottom: 40px;
    }

    &-socials {
      margin-bottom: 95px;

      a {
        margin-right: 20px;

        &:last-of-type {
          margin-right: 0;
        }

        svg {
          width: 25px;

          path {
            fill: white !important;
          }
        }
      }
    }
  }

  &__copy {
    font: 13px $roboto;
    font-weight: bold;
    opacity: .4;
  }
}

// @include desktop-lowres() {
//   .footer {
//     background-color: #333333;
//     color: white;
//     &__info {
//       &-socials {
//         a {
//           svg {
//             path {
//               fill: white !important;
//             }
//           }
//         }
//       }
//     }
//   }
// }