@import '../components/global-styles/media-mixins';
@import '../components/global-styles/font-families';

.volontiers {
  padding-bottom: 90px;
  p, li {
    font: 16px $roboto;
    margin-bottom: 20px;
    line-height: 1.8;
  }
  li {
    margin-top: 0;
  }
  ol {
    list-style-position: outside;
    padding-left: 30px;
    list-style-type: decimal;
  }
  ul {
    list-style-position: outside;
    padding-left: 20px;
    list-style-type: disc;
  }
}

@include tablet() {
  .volontiers {
    li {
      margin-top: 0;
    }
    ol {
      list-style-position: outside;
      padding-left: 40px;
      list-style-type: decimal;
    }
    ul {
      list-style-position: outside;
      padding-left: 40px;
      list-style-type: disc;
    }
  }
}

@include desktop() {
  .volontiers {
    padding-bottom: 120px;
    p, li {
      max-width: 950px;
      margin: 20px auto 0;
      font-size: 20px;
      line-height: 1.8;
    }
    li {
      margin-top: 0;
      padding-left: 10px;
    }
    ol {
      list-style-position: outside;
      padding-left: 120px;
      list-style-type: decimal;
    }
    ul {
      list-style-position: outside;
      padding-left: 30px;
      list-style-type: disc;
    }
  }
}