@import '../components/global-styles/media-mixins';
@import '../components/global-styles/font-families';

.program {
  padding-bottom: 50px;

  &__text {
    font: 16px $roboto;
    line-height: 1.8;

    &-date {
      font: 16px $roboto;
      font-weight: 700;
      color: #00c8c1;
      margin-bottom: 40px;
      margin-top: 40px;
    }
  }

  &__placeholder {
    height: calc(100vh - 80px);
    display: flex;
    justify-content: center;
    align-items: center;
    font: 40px $roboto;
    line-height: 1.5;
    text-align: center;
    font-weight: bold;
  }
}

@include desktop-lowres() {
  .program {
    padding-bottom: 130px;

    &__text {
      margin-left: 100px;
      line-height: 1.83;
      font-size: 18px;

      &-date {
        width: fit-content;
        margin: 90px auto 40px;
        font-size: 30px;
      }

      &-schedule {
        width: 950px;
      }
    }

    &__placeholder {
      height: calc(100vh - 86px);
      display: flex;
      justify-content: center;
      align-items: center;
      font: 40px $roboto;
      line-height: 1.5;
      text-align: center;
      font-weight: bold;
    }
  }
}