@import '../components//global-styles/media-mixins';

.home {
  position: relative;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  &__hero {
    width: 100%;
    padding-top: 50px;
    padding-bottom: 50px;
    min-height: calc(100vh - 84px);

    &-image {
      &-mobile {
        background-size: contain;
        width: 100%;
        height: 150vw;
      }

      &-desktop {
        display: none;
      }
    }
  }

  &__button {
    display: inline;
    position: absolute;
    bottom: 100px;
    left: 50%;
    transform: translateX(-50%);

    img {
      transition: opacity .3s;
    }

    img {
      width: 200px;

      &:hover {
        opacity: 0.8;
      }
    }
  }
}

@media only screen and (min-width: 558px) {
  .home {
    &__hero {
      &-image {
        height: 400px;
      }
    }

    &__button {
      img {
        width: 300px;
      }
    }
  }
}


@include desktop-lowres() {
  .home {
    &__hero {
      display: flex;
      align-items: center;
      justify-content: center;
      height: auto;
      padding-top: 0px;
      padding-bottom: 0px;

      &-image {


        &-mobile {
          display: none;
        }

        &-desktop {
          display: block;
          max-width: 100%;
          max-height: calc(100vh - 80px);

        }
      }
    }

    &__desktop-image {
      height: auto;
      width: 100%;
    }
  }
}

@include desktop() {
  .home {
    &__hero {
      height: auto;

      &-image {
        height: 700px;
      }
    }

    &__button {
      img {
        width: 400px;
      }
    }
  }
}

@include desktop-lg() {
  .home {
    &__hero {
      height: auto;

      &-image {
        height: 85vh;
      }
    }
  }
}