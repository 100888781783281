@import '../components/global-styles/media-mixins';
@import '../components/global-styles/font-families';

.official-selection-21 {
  &__content {
    margin-bottom: 80px;

    &-title {
      margin-top: 40px;
      margin-bottom: 30px;
      font: 16px $roboto;
      font-weight: bold;
    }

    &-text {
      font: 16px $roboto;
      margin-bottom: 10px;
      line-height: 20px;
    }
  }
}

@include desktop-lowres() {
  .official-selection-21 {
    &__content {
      max-width: 720px;
      margin: 0 auto 100px auto;

      &-title {
        margin-top: 40px;
        font-size: 24px;

        &:first-of-type {
          margin-top: 0px;
        }
      }
    }
  }
}