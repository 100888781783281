@import './media-mixins';

main {
  padding-top: 84px;
}

.no-scroll {
  overflow-y: hidden;
}

.content-wrapper {
  padding: 0 26px;
}

@include tablet() {
  .content-wrapper {
    padding: 0 50px;
  }
}

@include desktop-lowres() {
  main {
    padding-top: 75px;
  }

  .content-wrapper {
    max-width: 900px;
    margin: 0 auto;
    padding: 0;
  }
}

@include desktop() {
  .content-wrapper {
    max-width: 1200px;
  }
}