@import '../components/global-styles/media-mixins';
@import '../components/global-styles/font-families';

.masterclass {
  padding-bottom: 90px;

  &__text {
    font: 16px $roboto;
    line-height: 1.8;
    margin-bottom: 20px;

    &-bold {
      font: 16px $roboto;
      font-weight: 700;
    }
  }
}

@include desktop-lowres() {
  .masterclass {
    padding-bottom: 120px;

    &__text {
      max-width: 950px;
      margin: 20px auto;
      font-size: 20px;

      &-bold {
        max-width: 950px;
        margin: 20px auto;
        font-size: 20px;
      }
    }
  }
}