@import '../components/global-styles/media-mixins';

.gallery {
  padding-top: 50px;
  padding-bottom: 100px;
  min-height: 600px;
  &__slider {
    height: 250px !important;
  }
}

@include tablet() {
  .gallery {
    padding-top: 90px;
    padding-bottom: 130px;
    &__slider {
      height: 500px !important;
    }
  }
}

@include desktop-lowres() {
  .gallery {
    &__slider {
      height: 550px !important;
    }
  }
}

@include desktop() {
  .gallery {
    &__slider {
      height: 730px !important;
    }
  }
}